import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  SUBTITLES = document.querySelectorAll('.article-subtitle')

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 1.0
    })
    this.SUBTITLES.forEach(subtitle => this.observer.observe(subtitle))

    window.addEventListener('hashchange', this.handleInternalLinkChange.bind(this))
  }

  handleIntersect (entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.linkTargets.forEach(link => link.classList.remove('active'))

        const link = this.linkTargets.find(link => link.getAttribute('href').replace('#', '') === entry.target.id)
        link.classList.add('active')
      }
    })
  }

  handleInternalLinkChange(e) {
    this.linkTargets.forEach(link => link.classList.remove('active'))
    this.linkTargets.find(link => e.newURL.endsWith(link.getAttribute('href').replace('#', '')))?.classList.add('active')
  }

  disconnect () {
    this.SUBTITLES.forEach(subtitle => this.observer.unobserve(subtitle))
  }
}
