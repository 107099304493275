import { application } from './application'

import HwComboboxController from '@josefarias/hotwire_combobox'
application.register('hw-combobox', HwComboboxController)

import NavigationController from './navigation_controller'
application.register('navigation', NavigationController)

import ClipboardController from './clipboard_controller.js'
application.register('clipboard', ClipboardController)
