import { Controller } from '@hotwired/stimulus'

export default class Clipboard extends Controller {
  static targets = ['button', 'source', 'successContent']
  static classes = ['default', 'success']
  static values = {
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  connect () {
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy (event) {
    event.preventDefault()

    const text = this.sourceTarget.innerHTML || this.sourceTarget.value

    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContent
    this.toggleClasses()

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
      this.resetClasses()
    }, this.successDurationValue)
  }

  // Private

  toggleClasses () {
    if (!this.hasClasses) return
    this.buttonTarget.classList.remove(...this.defaultClasses)
    this.buttonTarget.classList.add(this.successClass)
  }

  resetClasses () {
    if (!this.hasClasses) return
    this.buttonTarget.classList.add(...this.defaultClasses)
    this.buttonTarget.classList.remove(this.successClass)
  }

  get successContent () {
    return this.successContentValue || this.successContentHtml
  }

  get successContentHtml () {
    if (this.hasSuccessContentTarget) {
      return this.successContentTarget.innerHTML
    }
  }

  get hasClasses () {
    return this.hasDefaultClass && this.hasSuccessClass
  }
}
